import { mapGetters } from "vuex"
import { goodsDetail, isCollect, isCart, addCollect, deleteCollect, addCart, deleteCart, searchMember, invoiceConfig, orderCreate } from '@/api/resource.js'
import Config from '@/utils/config';
export default {
    data: () => {
        return {
			current_mode: 1,
			process_list:['联系顾问','选定资源','在线付款','签署协议','交接流程','完成交易'],
			mobile:'',
			invoiceVisible:false,  //控制发票弹框的隐藏
			invoiceRules:{
				is_tax_invoice:[
					{required: true, message: '请选择发票类型', trigger: 'change'}
				],
				invoice_type:[
					{required: true, message: '请选择发票形式', trigger: 'change'}
				],
				invoice_title_type:[{
					required: true, message: '请选择发票抬头类型', trigger: 'change'
				}],
				invoice_content:[{
					required: true, message: '请选择发票内容', trigger: 'change'
				}],
				invoice_title:[{
					required: true, message: '请填写姓名', trigger: 'blur' 
				}],
				invoice_phone:[{
					required: true, message: '请填写电话', trigger: 'blur' 
				}],
				taxpayer_number:[{
					required: true, message: '请填写纳税人识别号', trigger: 'blur' 
				}],
				invoice_full_address:[{
					required: true, message: '请填写发票邮寄地址', trigger: 'blur' 
				}],
				invoice_email:[{
					required: true, message: '请填写发票接收邮箱', trigger: 'blur' 
				}],
				order_invoice_company:[{
					required: true, message: '请填写单位名称', trigger: 'blur' 
				}],
				invoice_company_address:[{
					required: true, message: '请填写单位地址', trigger: 'blur' 
				}],
				// invoice_company_credit:[{
				// 	required: true, message: '请填写统一社会信用代码', trigger: 'blur' 
				// }],
				invoice_company_number:[{
					required: true, message: '请填写账户号码', trigger: 'blur' 
				}],
				invoice_company_bank:[{
					required: true, message: '请填写开户行', trigger: 'blur' 
				}],
			},
			invoiceForm: {
				TaxData:[],
				paperData:[],
				is_tax_invoice:'',//是否专用发票
				invoice_type: '',//纸质or电子
				invoice_title_type: 1,//个人or企业
				// invoice_content:-1,//发票内容  索引
				invoice_content:'',//发票内容  索引
				invoice_content_array: [],
				order_invoice_company:'',
				taxpayer_number:'',//纳税人识别号
				invoice_title:'',//个人姓名
				invoice_phone:'',
				invoice_full_address:'',
				invoice_email:'',
				invoice_company_address:'',
				// invoice_company_credit:'',
				invoice_company_number:'',
				invoice_company_bank:'',
			},
			is_invoice:0,
			pay_money_type:'full',
			agent_info:'',
			enterpriseData: [
				{name: '个人',value: 1},
				{name: '企业',value: 2},
			],
			goods_loading:true,
			goods_info:{},
			invoice_data:'',
			toUid:'',
			flag:false,
		}
    },
    created() {
		this.getInvoiceConfig();
		if(this.orderCreateGoodsData){
			this.getGoodsInfo();
		}
    },
    computed: {
        ...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion","member","orderCreateGoodsData"])
    },
	methods: {
		deleteAgentInfo(){
			this.mobile = '';
			this.agent_info = null;
		},
		chooseIsInvoice(mode){
			this.is_invoice = mode;
			this.invoiceForm.is_tax_invoice = this.invoiceForm.is_tax_invoice ? this.invoiceForm.is_tax_invoice : this.invoiceForm.TaxData[0].value
			this.invoiceForm.invoice_type = this.invoiceForm.invoice_type ? this.invoiceForm.invoice_type : this.invoiceForm.paperData[0].value
		},
		toMemberDetail(id){
			this.$router.push('/personal-'+id);
		},
		toViewChat(){
			this.toUid = 'member_'+this.goods_info.member_info.member_id;
			this.$refs.servicerMessage.show();
		},
		createOrder(){
			if(this.is_invoice){
				if(!this.addmemberInvoice('invoiceForm')) return;
			}
			if(this.is_invoice && !this.invoice_data){
				this.$message.error('请填写发票信息');
				return;
			}
			if( !this.agent_info && !this.agent_info.member_id){
				this.$message.error('请选择成交经纪人');
				return;
			}
			if(this.flag) return;
			this.flag = true;
			orderCreate({
				goods_id:this.orderCreateGoodsData.goods_id,
				customer_agent_member_id:this.agent_info.member_id,
				pay_money_type:this.pay_money_type,
				is_invoice:this.is_invoice,
				invoice_data:this.is_invoice?this.invoice_data:''
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message:'下单成功',
						type:'success'
					});
					this.flag = false;
					setTimeout(()=>{
						// 跳到订单详情页面
						this.$router.push('/member/order_detail?order_id='+res.data.order_id+'&role=buyer')
					},800)
				}else{
					this.$message.error(res.message);
					this.flag = false;
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
				this.flag = false;
			})
		},
		goBack(){
			this.$router.push('/resource-'+this.orderCreateGoodsData.goods_id)
		},
		getGoodsInfo(){
			goodsDetail({
				goods_id:this.orderCreateGoodsData.goods_id
			})
			.then(res=>{
				if(res.code>=0){
					this.goods_info = this.$util.deepClone(res.data);
					if(this.goods_info.goods_attr_format) this.goods_info.goods_attr_parse = JSON.parse(this.goods_info.goods_attr_format);
					if(this.goods_info.fee_data){
						this.goods_info.fee_data = JSON.parse(this.goods_info.fee_data);
					}
					if(this.goods_info.trade_progress) this.process_list = JSON.parse( this.goods_info.trade_progress );
					this.goods_loading = false;
				}else{
					this.$message.error(res.message);
					setTimeout(()=>{
						this.$router.push('/resource-'+this.orderCreateGoodsData.goods_id)
					},800)
				}
			})
		},
		searchMobile(){
			var phoneReg = /^1[3-9]\d{9}$/;
			if(!this.mobile){
				this.$message.error('请输入手机号码');
				return;
			}
			if(!phoneReg.test(this.mobile)){
				this.$message.error('手机号码格式不正确');
				return;
			}
			searchMember({
				mobile:this.mobile,
				is_agent:1
			})
			.then(res=>{
				if(res.code>=0){
					if(res.data){
						this.agent_info = res.data;
					}else{
						this.$message.error('查找不到对应的经纪人');
					}
				}else{
					this.$message.error(res.message);
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
			})
		},
		// 新增发票信息
		addmemberInvoice(invoiceForm) {
			// 判断点击哪个店铺的商品,拿到当前的site_id里面的数据
			var phonereg = /^1[3-9]\d{9}$/;
			var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
			//验证表单
			if(this.invoiceForm.is_tax_invoice == 1 ){
				//普票
				if(!this.invoiceForm.invoice_phone){
					this.$message.error({
						message: '电话不能为空',
						type: "warning"
					})
					return false;
				}
				if(!phonereg.test(this.invoiceForm.invoice_phone)){
					this.$message.error({
						message: '电话格式有误',
						type: "warning"
					})
					return false;
				}
				if(this.invoiceForm.invoice_title_type == 1){
					//个人
					if(!this.invoiceForm.invoice_title){
						this.$message.error({
							message: '姓名不能为空',
							type: "warning"
						})
						return false;
					}
					
				}else{
					//企业
					if(!this.invoiceForm.order_invoice_company){
						this.$message.error({
							message: '单位名称不能为空',
							type: "warning"
						})
						return false;
					}
					if(!this.invoiceForm.taxpayer_number){
						this.$message.error({
							message: '纳税人识别号不能为空',
							type: "warning"
						})
						return false;
					}
				}
			}else{
				//专票
				if(!this.invoiceForm.invoice_phone){
					this.$message.error({
						message: '电话不能为空',
						type: "warning"
					})
					return false;
				}
				if(!phonereg.test(this.invoiceForm.invoice_phone)){
					this.$message.error({
						message: '电话格式有误',
						type: "warning"
					})
					return false;
				}
				if(!this.invoiceForm.order_invoice_company){
					this.$message.error({
						message: '单位名称不能为空',
						type: "warning"
					})
					return false;
				}
				if(!this.invoiceForm.taxpayer_number){
					this.$message.error({
						message: '纳税人识别号不能为空',
						type: "warning"
					})
					return false;
				}
				if(!this.invoiceForm.invoice_company_address){
					this.$message.error({
						message: '单位地址不能为空',
						type: "warning"
					})
					return false;
				}
				// if(!this.invoiceForm.invoice_company_credit){
				// 	this.$message.error({
				// 		message: '统一社会信用代码不能为空',
				// 		type: "warning"
				// 	})
				// 	return false;
				// }
				if(!this.invoiceForm.invoice_company_number){
					this.$message.error({
						message: '账户号码不能为空',
						type: "warning"
					})
					return false;
				}
				if(!this.invoiceForm.invoice_company_bank){
					this.$message.error({
						message: '开户行不能为空',
						type: "warning"
					})
					return false;
				}
			}
			if(this.invoiceForm.invoice_type == 1){
				//纸质
				if(!this.invoiceForm.invoice_full_address){
					this.$message.error({
						message: '发票邮寄地址不能为空',
						type: "warning"
					})
					return false;
				}
			}else{
				//电子
				if(!this.invoiceForm.invoice_email){
					this.$message.error({
						message: '邮箱不能为空',
						type: "warning"
					})
					return false;
				}
				if(!emailReg.test(this.invoiceForm.invoice_email)){
					this.$message.error({
						message: '邮箱格式有误',
						type: "warning"
					})
					return false;
				}
			}
			if(this.invoiceForm.invoice_content == -1 ){
				this.$message.error({
					message: '发票内容不能为空',
					type: "warning"
				})
				return false;
			}
			this.invoice_data = JSON.stringify(this.invoiceForm);
			return true;
			// this.invoiceVisible = false
		},
		openInvoicePopup(){
			this.invoiceForm.is_tax_invoice = this.invoiceForm.is_tax_invoice ? this.invoiceForm.is_tax_invoice : this.invoiceForm.TaxData[0].value
			this.invoiceForm.invoice_type = this.invoiceForm.invoice_type ? this.invoiceForm.invoice_type : this.invoiceForm.paperData[0].value
			this.invoiceVisible = true;
		},
		getInvoiceConfig(){
			invoiceConfig()
			.then(res=>{
				if(res.code>=0){
					if (res.data.invoice_content) {
						this.invoiceForm.invoice_content_array = res.data.invoice_content
					} else {
						this.invoiceForm.invoice_content_array = [];
					}
					if(res.data.tax_type.length){
						this.invoiceForm.TaxData = [];
						res.data.tax_type.forEach(item=>{
							if(item==1){
								this.invoiceForm.TaxData.push({name: '普通发票',value: 1})
							}
							if(item==2){
								this.invoiceForm.TaxData.push({name: '专用发票',value: 2})
							}
						})
					}
					if(res.data.type.length){
						this.invoiceForm.paperData = [];
						res.data.type.forEach(item=>{
							if(item==1){
								this.invoiceForm.paperData.push({name: '纸质发票',value: 1})
							}
							if(item==2){
								this.invoiceForm.paperData.push({name: '电子发票',value: 2})
							}
						})
					}
				}
			})
		},
		changeMode(mode) {
		    this.current_mode = mode
		    console.log(this.current_mode == 1 ? "资源" : "需求")
		},
	}
}
