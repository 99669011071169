<template>
	<div class="buy_payment">
		<top-search @changeMode="changeMode"></top-search>
		<div class="process">
			<div class="item" v-for="(item,index) in process_list" :key="index">
				<div class="top">
					<div class="line"></div>
					<div class="center"><div>{{index+1}}</div></div>
					<div class="line"></div>
				</div>
				<div class="tit">{{item}}</div>
			</div>
		</div>
		<div class="payment_info" v-loading="goods_loading">
			<div class="goods">
				<div class="info">
					<div class="top">
						<div class="number">编号：{{goods_info.goods_code}}</div>
						<div class="time">{{$util.timeStampTurnTime(goods_info.modify_time).split(' ')[0].replaceAll('-','/')}}</div>
					</div>
					<div class="name">{{goods_info.goods_name}}</div>
					<div class="goods_tags">
						<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
						<div class="tag">{{goods_info.category_name}}</div>
						<div class="tag" v-for="(aItem,aIndex) in goods_info.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
					</div>
					<div class="accredits">
						<div class="item" v-for="(aItem,aIndex) in goods_info.goods_attr_parse" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
					</div>
				</div>
				<div class="middle">
					<div class="item" v-for="(item,index) in goods_info.fee_data" :key="index">
						<div class="left">{{item.title}}</div>
						<div class="price">{{item.price}}</div>
					</div>
					
					<div class="item">
						<div class="left">待付款</div>
						<div class="total">￥{{goods_info.price}}</div>
					</div>
				</div>
				<div class="member" v-if="goods_info.member_info">
					<img class="headimg" @click="toMemberDetail(goods_info.member_info.member_id)" :src="$img(goods_info.member_info.headimg)" @error="goods_info.member_info.headimg = defaultHeadImage " alt="">
					<div class="name">{{goods_info.member_info.nickname}}</div>
					<div class="bottom_btn">
						<div @click="toViewChat()">消息</div>
						<div class="wx">微信<img v-if="goods_info.member_info.wechat_personal_qrcode" class="wechat_img" :src="$img(goods_info.member_info.wechat_personal_qrcode)" /></div>
					</div>
				</div>
			</div>
			<div class="payment">
				<div class="setting">
					<div class="left">发票</div>
					<div class="invoice_right">
						<div class="top">
							<div class="btn" :class="is_invoice?'':'active'" @click="chooseIsInvoice(0)">不需要</div>
							<div class="btn" :class="is_invoice?'active':''" @click="chooseIsInvoice(1)">需要</div>
						</div>
						<div class="bottom" v-if="is_invoice">
							<el-form class="invoice_form" :model="invoiceForm" :rules="invoiceRules" ref="invoiceForm" >
								<el-form-item prop="is_tax_invoice" :show-message="false">
									<el-select v-model="invoiceForm.is_tax_invoice" placeholder="发票类型" >
									    <el-option
									      v-for="item in invoiceForm.TaxData"
									      :key="item.value"
									      :label="item.name"
									      :value="item.value">
									    </el-option>
									</el-select>
								</el-form-item>
								<el-form-item  prop="invoice_type" :show-message="false">
									<el-select v-model="invoiceForm.invoice_type" placeholder="发票形式" >
									    <el-option
									      v-for="item in invoiceForm.paperData"
									      :key="item.value"
									      :label="item.name"
									      :value="item.value">
									    </el-option>
									</el-select>
								</el-form-item>
								<el-form-item prop="invoice_title_type" v-if="invoiceForm.is_tax_invoice==1" :show-message="false">
									<el-select v-model="invoiceForm.invoice_title_type" placeholder="抬头类型" >
									    <el-option
									      v-for="item in enterpriseData"
									      :key="item.value"
									      :label="item.name"
									      :value="item.value">
									    </el-option>
									</el-select>
								</el-form-item>
								<el-form-item prop="order_invoice_company" v-if="(invoiceForm.invoice_title_type == 2) || invoiceForm.is_tax_invoice==2 " :show-message="false">
									<el-input class="shuru" v-model="invoiceForm.order_invoice_company" placeholder="单位名称"></el-input>
								</el-form-item>
								<el-form-item prop="taxpayer_number" v-if="invoiceForm.invoice_title_type == 2 || invoiceForm.is_tax_invoice==2 " :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.taxpayer_number" placeholder="纳税人号"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_title" v-if="invoiceForm.is_tax_invoice == 1 && invoiceForm.invoice_title_type ==1 " :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.invoice_title" placeholder="姓名"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_phone" :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.invoice_phone" placeholder="电话"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_full_address" v-if="invoiceForm.invoice_type == 1" :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.invoice_full_address" placeholder="邮寄地址"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_email" v-if="invoiceForm.invoice_type == 2" :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.invoice_email" placeholder="接收邮箱"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_company_address" v-if="invoiceForm.is_tax_invoice == 2" :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.invoice_company_address" placeholder="单位地址"></el-input>
								</el-form-item>
								<!-- <el-form-item  prop="invoice_company_credit" v-if="invoiceForm.is_tax_invoice == 2">
									<el-input v-model="invoiceForm.invoice_company_credit" placeholder="统一社会信用代码"></el-input>
								</el-form-item> -->
								<el-form-item  prop="invoice_company_number" v-if="invoiceForm.is_tax_invoice == 2" :show-message="false">
									<el-input class="shuru"  v-model="invoiceForm.invoice_company_number" placeholder="账户号码"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_company_bank" v-if="invoiceForm.is_tax_invoice == 2" :show-message="false">
									<el-input class="shuru" v-model="invoiceForm.invoice_company_bank" placeholder="开户行"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_content" v-if="invoiceForm.invoice_content_array.length > 0" :show-message="false">
									<el-select v-model="invoiceForm.invoice_content" placeholder="发票内容" >
										<el-option
										  v-for="(item,index) in invoiceForm.invoice_content_array"
										  :key="index"
										  :label="item"
										  :value="index">
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div class="setting">
					<div class="left">类型</div>
					<div class="right">
						<div class="btn" :class="pay_money_type=='full'?'active':''" @click="pay_money_type = 'full'">全款</div>
						<div class="btn" :class="pay_money_type=='deposit'?'active':''" @click="pay_money_type = 'deposit'">定金</div>
					</div>
				</div>
				<div class="setting">
					<div class="left">成交经纪人</div>
					<div class="right search">
						<div class="top">
							<div class="agent_info" v-if="agent_info">
								<img class="circle" :src="$img(agent_info.headimg)" alt="" @error="agent_info.headimg = defaultHeadImage ">
								<div class="info">{{agent_info.realname ? agent_info.realname: agent_info.nickname}} {{agent_info.mobile}}</div>
								<div class="delete_btn el-icon-error" @click="deleteAgentInfo()"></div>
							</div>
							<input v-else type="text" placeholder="手机号" v-model="mobile">
							<div class="btn" @click="searchMobile()">搜索</div>
						</div>
					</div>
				</div>
			</div>
			<div class="actions">
				<div class="btn" @click="goBack()">取消订单</div>
				<div class="btn" @click="createOrder()">创建订单</div>
			</div>
		</div>
		<el-dialog title="添加发票信息" :visible.sync="invoiceVisible" width="720px">
			<el-form :model="invoiceForm" :rules="invoiceRules" ref="invoiceForm" label-width="150px">
				<el-form-item label="发票类型" prop="is_tax_invoice">
					<el-radio-group v-model="invoiceForm.is_tax_invoice">
						<el-radio :label="item.value" v-for="(item,index) in invoiceForm.TaxData" :key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="发票形式" prop="invoice_type">
					<el-radio-group v-model="invoiceForm.invoice_type">
						<el-radio :label="item.value" v-for="(item,index) in invoiceForm.paperData" :key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="发票抬头类型" prop="invoice_title_type" v-if="invoiceForm.is_tax_invoice==1">
					<el-radio-group v-model="invoiceForm.invoice_title_type">
						<el-radio :label="item.value" v-for="(item,index) in enterpriseData" :key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="单位名称" prop="order_invoice_company" v-if="(invoiceForm.invoice_title_type == 2) || invoiceForm.is_tax_invoice==2 ">
					<el-input v-model="invoiceForm.order_invoice_company" placeholder="请填写单位名称"></el-input>
				</el-form-item>
				<el-form-item label="纳税人识别号" prop="taxpayer_number" v-if="invoiceForm.invoice_title_type == 2 || invoiceForm.is_tax_invoice==2 ">
					<el-input v-model="invoiceForm.taxpayer_number" placeholder="请填写纳税人识别号"></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="invoice_title" v-if="invoiceForm.is_tax_invoice == 1 && invoiceForm.invoice_title_type ==1 ">
					<el-input v-model="invoiceForm.invoice_title" placeholder="请填写姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="invoice_phone">
					<el-input v-model="invoiceForm.invoice_phone" placeholder="请填写电话"></el-input>
				</el-form-item>
				<el-form-item label="发票邮寄地址" prop="invoice_full_address" v-if="invoiceForm.invoice_type == 1">
					<el-input v-model="invoiceForm.invoice_full_address" placeholder="请填写发票邮寄地址"></el-input>
				</el-form-item>
				<el-form-item label="接收邮箱" prop="invoice_email" v-if="invoiceForm.invoice_type == 2">
					<el-input v-model="invoiceForm.invoice_email" placeholder="请填写接收邮箱"></el-input>
				</el-form-item>
				<el-form-item label="单位地址" prop="invoice_company_address" v-if="invoiceForm.is_tax_invoice == 2">
					<el-input v-model="invoiceForm.invoice_company_address" placeholder="请填写单位地址"></el-input>
				</el-form-item>
				<!-- <el-form-item label="统一社会信用代码" prop="invoice_company_credit" v-if="invoiceForm.is_tax_invoice == 2">
					<el-input v-model="invoiceForm.invoice_company_credit" placeholder="请填写统一社会信用代码"></el-input>
				</el-form-item> -->
				<el-form-item label="账户号码" prop="invoice_company_number" v-if="invoiceForm.is_tax_invoice == 2">
					<el-input v-model="invoiceForm.invoice_company_number" placeholder="请填写账户号码"></el-input>
				</el-form-item>
				<el-form-item label="开户行" prop="invoice_company_bank" v-if="invoiceForm.is_tax_invoice == 2">
					<el-input v-model="invoiceForm.invoice_company_bank" placeholder="请填写开户行"></el-input>
				</el-form-item>
				<el-form-item label="发票内容" prop="invoice_content" v-if="invoiceForm.invoice_content_array.length > 0">
					<el-radio-group v-model="invoiceForm.invoice_content">
						<el-radio :label="index" v-for="(item,index) in invoiceForm.invoice_content_array" :key="index">{{item}}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="invoiceVisible = false">取 消</el-button>
				<el-button type="primary" @click="addmemberInvoice('invoiceForm')">确 定</el-button>
			</span>
		</el-dialog>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import servicerMessage from "@/components/message/servicerMessage"
	import topSearch from '@/components/top_search';
	import detail from './payment.js';
	export default {
		name: 'payment',
		components: {
			topSearch,
			servicerMessage
		},
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment.scss';
</style>
<style lang="scss">
	.invoice_form{
		.shuru{
			border-radius: 5px;
			background-color: #F7F8FB;
			font-family: "PingFang SC";
			line-height: 1;
			padding:10px 0;
			.el-input__inner{
				background-color: transparent;
				border:none;
				text-align: center;
				height: auto;
				padding: 0;
				border-radius: 5px;
				color: #30373D;
				font-size: 15px;
				line-height: 21px;
				&::placeholder{
					font-size: 15px;
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
		}
		.el-select{
			width: 100%;
			background-color: #F7F8FB;
			border-radius: 5px;
			padding: 10px 0;
			.el-input--suffix{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				.el-input__inner{
					width: 25%;
					background-color: transparent;
					border:none;
					padding: 0;
					height: auto;
					text-align: center;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					&::placeholder{
						color: rgba(48, 55, 61, 0.50);
						font-family: "PingFang SC";
					}
				}
				.el-input__suffix{
					position: relative;
					right: 0;
					height: auto;
					margin-left: 10px;
					text-align: left;
					.el-input__suffix-inner{
						line-height: 12px;
						display: block;
					}
				}
			}
		}
		
	}
	.el-select-dropdown{
		.el-scrollbar{
			.el-scrollbar__view{
				.el-select-dropdown__item{
					text-align: center;
				}
			}
		}
	}
	.invoice_form .el-select .el-input .el-select__caret {
	  transform: rotateZ(0deg);
	  width: 12px;
	  font-size: 12px;
	  line-height: 1;
	}
	.invoice_form .el-select .el-input .el-select__caret::before {
	  content: "\e790";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 50%;
	  left: 50%;
	  color: #30373D !important;
	  transform: translate(-50%, -50%);
	}
</style>